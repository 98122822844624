import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useState } from "react";
import "./DashboardTable.scss";
import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  [key: string]: any;
}

interface Action {
  label: string;
  buttonLabel: string;
  onClick: (row: Data, cont: any) => void;
}

interface DashBoardTableProps {
  headCells: HeadCell[];
  rows: Data[];
  ButtonActions?: Action[];
  onValueChange?: any;
  pageNumber?: any;
  pageType?: any;
  pageChange?: any;
  cont?: any;
}

export default function DashBoardTable({
  headCells,
  rows,
  ButtonActions,
  onValueChange,
  pageType,
  pageNumber,
  pageChange,
}: DashBoardTableProps) {
  //handleChangePage = pageChange;
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>(headCells[0].id);
  //eslint-disable-next-line
  const [selected, setSelected] = useState<readonly number[]>([]);
  //eslint-disable-next-line
  const [page, setPage] = useState(0);
  //eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //eslint-disable-next-line
  const [dense, setDense] = useState(false);
  const [updatedRows, setUpdatedRows] = useState<Data[]>([]);
  const { t }: { t: (key: string) => string } = useTranslation();

  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  //eslint-disable-next-line
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const title = window.location.pathname.split("/");
  const selectedItem =
    title[title.length - 1].charAt(0).toUpperCase() +
    title[title.length - 1].slice(1);

  const isSelected = (index: number) => selected.indexOf(index) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleQuantityChange = (event: any, index: number) => {
    const { value } = event.target;
    setUpdatedRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], quantity: parseInt(value) || 0 };
      return newRows;
    });
    onValueChange(updatedRows);
  };

  const handlePriceChange = (event: any, index: number) => {
    const { value } = event.target;
    setUpdatedRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], itemPrice: parseFloat(value) || 0 };
      return newRows;
    });
    onValueChange(updatedRows);
  };

  useEffect(() => {
    if (onValueChange !== undefined) {
      onValueChange(updatedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange, updatedRows]);


  return (
    <Box sx={{ width: "100%" }} className="commmon-tables desktop-table-block">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Grid item xs={12} sm={12} md={7}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {/*<TextField
              type="text"
              value=""
              sx={{ width: '350px' }}
              placeholder="Search Order"
            />*/}
            {/*<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='order-tabs-head'>
                <Tab label="Pending" {...a11yProps(0)} className='tabs-title' />
              </Tabs>*/}
          </Box>
        </Grid>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows?.length}
                    checked={rows?.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all orders" }}
                  />
                </TableCell> */}
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "left" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {/* <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    > */}
                    {headCell.label}
                    {/* {orderBy === headCell.id ? (
                        <Box component="span" sx={{ ...visuallyHidden }}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                      ) : null} */}
                    {/* </TableSortLabel> */}
                  </TableCell>
                ))}
                {/* {ButtonActions && ButtonActions.map((action, index) => (
                  <> */}
                {pageType === "claims" ? (
                  <TableCell>Action</TableCell>
                ) : (
                  ButtonActions &&
                  ButtonActions.map((action, index) => (
                    <TableCell key={index}>{action.label}</TableCell>
                  ))
                )}

                {/* </>
                ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedRows &&
                rows &&
                rows.map((row, index) => {
                  const isItemSelected = isSelected(index);
                  //eslint-disable-next-line
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, index)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => handleClick(event, index)}
                      />
                    </TableCell> */}
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "left" : "left"}
                        >
                          {headCell.id === "quantity" &&
                          selectedItem === "Product" ? (
                            <TextField
                              type="number"
                              // value={row[headCell.id]}
                              id="search_product"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={updatedRows[index]?.quantity || 0}
                              onChange={(event) =>
                                handleQuantityChange(event, index)
                              }
                              sx={{ width: "100px" }}
                            />
                          ) : headCell.id === "itemPrice" &&
                            selectedItem === "Product" ? (
                            <TextField
                              type="number"
                              id="search_product"
                              value={updatedRows[index]?.itemPrice || 0}
                              InputProps={{ inputProps: { min: 0 } }}
                              onChange={(event) =>
                                handlePriceChange(event, index)
                              }
                              sx={{ width: "100px" }}
                            />
                          ) : headCell.id === "image" ? (
                            <img
                              src={row[headCell.id]}
                              alt="Product"
                              style={{ width: 50, height: 50 }}
                            />
                          ): headCell.id === "claim_id" ? (
                            // <Badge
                            //   badgeContent={row.count}
                            //   sx={{
                            //     "& .MuiBadge-badge": {
                            //       backgroundColor: "#c55050",
                            //       color: "#fff",
                            //       fontSize: "12px",
                            //       minWidth: "20px",
                            //       height: "20px",
                            //       borderRadius: "10px",
                            //     },
                            //   }}
                            //   invisible={!row.count}
                            // >
                            <Link
                              to={`viewclaim/claim_Details?claims_id=${row.entity_id}`}
                              style={{
                                color: "#1976d2",
                                textDecoration: "none",
                                fontWeight: "bold",
                              }}
                            >
                              {row[headCell.id]}
                            </Link>
                            // </Badge>
                          )  :  headCell.id === "count" ? (
                            
                            <Link
                              to={`viewclaim/claim_Details?claims_id=${row.entity_id}`}
                              style={{
                                // color: "#1976d2",
                                textDecoration: "none",
                                fontWeight: "bold",
                              }}
                            >
                              {row[headCell.id]}
                            </Link>
                           
                          ) : headCell.id === "waybillNumber" &&
                            row[headCell.id] !== "" ? (
                            //eslint-disable-next-line
                            <a href={row["waybillFile"]} target="_blank">
                              {row[headCell.id]}
                            </a>
                          ) : (
                            <>
                              {headCell.id === "status" ? (
                                row[headCell.id] === "1" ? (
                                  "In Stock"
                                ) : (
                                  "Out of Stock"
                                )
                              ) : headCell.id === "download" ? (
                                //eslint-disable-next-line
                                <a href={`${row[headCell.id]}`} target="_blank">
                                  Download
                                </a>
                              ) : (
                                row[headCell.id]
                              )}
                            </>
                          )}
                          {/* {row[headCell.id]} previously when Quantity was not inputField*/}
                        </TableCell>
                      ))}
                      {ButtonActions && ButtonActions.length > 0 && (
                        <TableCell>
                          {pageType === "claims" ? (
                            <Select
                              value=""
                              displayEmpty
                              className="dropdown-button"
                              MenuProps={{
                                className: "action-menu",
                              }}
                              onChange={(event: any) => {
                                const selectedAction = ButtonActions.find(
                                  (action) =>
                                    action.buttonLabel === event.target.value
                                );
                                if (selectedAction) {
                                  selectedAction.onClick(
                                    row,
                                    `custom-select-${index}`
                                  );
                                }
                              }}
                            >
                              <MenuItem
                                // key={actionIndex}
                                value=""
                                // onClick={() => action.onClick(row, `custom-select-item-${actionIndex}`)}
                                disabled
                              >
                                 {t("Home_Dashboard.claims.full_refund_lable")}
                              </MenuItem>
                              {ButtonActions?.map((action, actionIndex) => (
                                <MenuItem
                                  key={actionIndex}
                                  value={action.buttonLabel}
                                  onClick={() =>
                                    action.onClick(
                                      row,
                                      `custom-select-item-${actionIndex}`
                                    )
                                  }
                                >
                                  {action.buttonLabel}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            // Regular button for other pages
                            <>
                              {ButtonActions.map((action, index) => (
                                <Button
                                  key={index}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    action.onClick(row, `custom-btn-${index}`)
                                  }
                                  className={`custom-btn-${index}`}
                                  sx={{
                                    marginRight:
                                      index < ButtonActions.length - 1 ? 1 : 0,
                                  }}
                                >
                                  {action.buttonLabel}
                                </Button>
                              ))}
                            </>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell
                    colSpan={
                      headCells.length +
                      (ButtonActions ? ButtonActions.length : 0)
                    }
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/*<Box sx={{ pt: 5 }}>
          <Stack spacing={2}>
            <Pagination
              count={(rows?.length/10 > 1) ? Math.round(rows?.length/10) : 0}
              shape="rounded"
              onChange={handleChangePage}
              defaultPage={selectedPage}
            />
          </Stack>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          className="paginations"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />*/}
      </Paper>
    </Box>
  );
}
