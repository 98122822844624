import React, { useContext } from "react";
import "./NewTopbar.scss";
import { Button, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import CountryFlag from "./CountryFlag";
import { useTranslation } from "react-i18next";
// import i18n from "../../i18n";
import { CountryContext } from "../..";
import { useNavigate } from "react-router-dom";

const convertTitle = (path: any,t: (key: string) => string) => {
  const parts = path.split("/");
  const lastPart = parts[parts.length - 1];

  // Map last part to a more readable format
  const titleMappings: { [key: string]: string } = {
    dashboard: t("Home_Dashboard.topbar.dashboard"),
    product: t("Home_Dashboard.topbar.product"),
    orders: t("Home_Dashboard.topbar.orders"),
    returns: t("Home_Dashboard.topbar.returns"),
    payment: t("Home_Dashboard.topbar.payments"),
    claims: t("Home_Dashboard.sidebar.claims"),
    settings: t("Home_Dashboard.topbar.settings"),
    "edit-bank-details": t("Home_Dashboard.topbar.edit_bank_details"),
    "edit-name": t("Home_Dashboard.topbar.edit_name"),
    "edit-email": t("Home_Dashboard.topbar.edit_email"),
    "change-password": t("Home_Dashboard.topbar.change_pwd"),
    "edit-pickup-details": t("Home_Dashboard.topbar.edit_pckp_details"),
    claim_Details: "Claims details",
  };

  // If there's a mapping for the last part, return it; otherwise, return the original last part
  return (
    titleMappings[lastPart] ||
    lastPart.charAt(0).toUpperCase() + lastPart.slice(1)
  );
};

const Topbar = () => {
  // const title = window.location.pathname.split("/");
  const { t }: { t: (key: string) => string } = useTranslation();
  const title = convertTitle(window.location.pathname,t);
  const {toggleLanguage , store} = useContext(CountryContext);
  const navigate = useNavigate()

  return (
    <>
      <header className="header directionrtl">
        <div className="header_inner">
          <div className="left_part">
            <button
              className="btn btn-light btn_close d-lg-none d-md-block"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </button>
            {title[title.length - 1] === "dashboard" ? (
              <Button className="topbar-btn">
                <Typography className="topbar-btn-text">
                {t("Home_Dashboard.topbar.ask_questions")}
                </Typography>
              </Button>
            ) : (
              <Typography variant="h4" className="main-titles">
                {title}
              </Typography>
            )}
          </div>
          <div className="right_part">
            <div className="right-sidebar">
            {store !== "sell_in" && <Button
                  className="btn btn-primary language-toggle"
                  style={{
                    backgroundColor: "transparent",
                    color: "inherit",
                    textTransform: "unset",
                    // opacity: store === "sell_in" ? 0.5 : 1,
                    border: "none",
                    fontWeight:"700"
                  }}
                  onClick={toggleLanguage}
                  // disabled={store === "sell_in"}
                >
                  {t("switchLanguage")}
                </Button>}
                <div className="country_flag">
                <CountryFlag />
                </div>
              <div className="icons">
                <NotificationsIcon />
                <span>0</span>
              </div>
              <div className="icons">
                <PersonIcon onClick={()=>{
                  navigate(`/dashboard/settings`)
                }}
                sx={{cursor:'pointer'}}/>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Topbar;
