import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif", 
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif", 
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif",
        },
      },
    },
  },
});

export default theme;