import { gql } from "@apollo/client";

export const GET_SELLER_CLAIMS = gql`
  query {
    sellerclaimList {
      image
      claimid
      entity_id
      date
      product_name
      qty
      price
      totalprice
      claim_status
      sku
      issue_type
      description
      customeremail
      customername
      order_id
      product_image_url
      communication {
      sender
      text
      timestamp
      is_read_by_customer
      is_read_by_seller
    }
    }
  }
`;

export const VIEW_CLAIM = gql`
  query viewSellerSideClaim($entity_id: Int!) {
    viewSellerSideClaim(entity_id: $entity_id) {
      claimid
      customer_id
      customer_email
      order_id
      item_id
      date
      product_name
      qty
      price
      totalprice
      claim_status
      issue_type
      description
      image
      product_image_url
    }
  }
`;

export const GET_CHAT_COMMUNICATION = gql`
  query getChatCommunication($claimid: Int!) {
    getChatCommunication(claimid: $claimid) {
      claimid
      communication {
        sender
        text
        timestamp
        is_read_by_customer
        is_read_by_seller
      }
    }
  }
`;
