import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DashBoardTable from "../../../Components/Dashboard_Table/DashboardTable";
import MobileTable from "../Order/mobileTable/MobileTable";
import ClaimsActionModal from "./claimAction/ClaimsActionModal";
import NoProductFound from "../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { claimsPageQuery } from "../../../Operations/Queries";
import { CountryContext } from "../../..";

interface ClaimData {
  id: string;
  OrderNumber: string;
  date: string;
  ProductName: string;
  issueType: string;
  description: string;
  status: string;
}

const ClaimsPage: React.FC = () => {
  const [claims, setClaims] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState<ClaimData | null>(null);
  const [refundType, setRefundType] = useState<string>("");
  const [claimsData, setClaimsData] = useState<any[]>();
  const [sellerUnreadCount, setSellerUnreadCount] = useState(0);
  
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);

  const {
    data: claimsDataIs,
    loading: loadingClaims,
    refetch: refetchClaimsData,
    error: claimsError,
  } = useQuery(claimsPageQuery.GET_SELLER_CLAIMS, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const processClaims = (claims: any) => {
    return claims.map((claim: any) => {
      const communication = claim?.communication || [];

      let read_by_customer = 0;
      let unread_by_customer = 0;
      let read_by_seller = 0;
      let unread_by_seller = 0;

      communication?.forEach((msg: any) => {
        msg.is_read_by_customer ? read_by_customer++ : unread_by_customer++;
        msg.is_read_by_seller ? read_by_seller++ : unread_by_seller++;
      });

      return {
        ...claim,
        read_by_customer,
        unread_by_customer,
        read_by_seller,
        unread_by_seller,
      };
    });
  };

  useEffect(() => {
    if (claimsDataIs?.sellerclaimList) {
      const enrichedClaims = processClaims(claimsDataIs.sellerclaimList);
      setClaimsData(enrichedClaims);
    }
  }, [claimsDataIs]);

  // const claimsData = claimsDataIs?.sellerclaimList;

  useEffect(() => {
    const transformed = claimsData?.map((claim: any) => ({
      image: claim?.product_image_url,
      claim_id: claim?.claimid,
      entity_id: claim?.entity_id,
      date: claim?.date
        ? new Date(claim?.date)?.toLocaleDateString("en-GB")
        : claim?.date,
      itemName: claim?.product_name,
      itemPrice: claim?.totalprice,
      claims_status:
      claim?.claim_status === "pending"
        ? t("Home_Dashboard.claims.pending")
        : claim?.claim_status === "in_review"
        ? t("Home_Dashboard.claims.in_review")
        : t("Home_Dashboard.claims.close"),
      count: claim?.unread_by_seller,
    }));
    setClaims(transformed || []);
    //eslint-disable-next-line
  }, [claimsData]);

const countAllUnreadMessages = (claims: any[]) => {
  return claims?.reduce((total, claim) => {
    const claimUnread = claim.communication?.filter(
      (msg: any) => msg.sender === "customer" && msg.is_read_by_seller === 0
    ).length || 0;
    return total + claimUnread;
  }, 0) || 0;
};

useEffect(() => {
  if (claimsDataIs?.sellerclaimList) {
    const unreadCount = countAllUnreadMessages(claimsDataIs?.sellerclaimList);
    setSellerUnreadCount(unreadCount);
  }
}, [claimsDataIs?.sellerclaimList]);

  useEffect(() => {
    refetchClaimsData();
  }, [refetchClaimsData, claimsDataIs?.sellerclaimList, store]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    refetchClaimsData();
  };

  const array: any = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
  ];

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const totalItems = claims?.length || 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day).getTime();
  };

  const paginatedData = Array.isArray(claims)
    ? [...claims]
        .sort((a: any, b: any) => parseDate(b.date) - parseDate(a.date))
        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleActionClick = (claim: ClaimData) => {
    setSelectedClaim(claim);
  };

  const additionalActionsForEditClaims = [
    {
      label: t("Home_Dashboard.claims.full_refund_lable"),
      buttonLabel: t("Home_Dashboard.claims.full_refund_lable"),
      onClick: (row: any) => {
        setModalOpen(true);
        handleActionClick(row);
        setRefundType("full_refund");
      },
    },
    {
      label: t("Home_Dashboard.claims.partial_refund_lable"),
      buttonLabel: t("Home_Dashboard.claims.partial_refund_lable"),
      onClick: (row: any) => {
        setSelectedClaim(row);
        setModalOpen(true);
        setRefundType("partial_refund");
      },
    },
    {
      label: t("Home_Dashboard.claims.reject_lable"),
      buttonLabel: t("Home_Dashboard.claims.reject_lable"),
      onClick: (row: any) => {
        setSelectedClaim(row);
        setModalOpen(true);
        setRefundType("rejected");
      },
    },
  ];

  const headCellsForClaims = [
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.product"),
    },
    {
      id: "claim_id",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.claim"),
    },
    {
      id: "count",
      numeric: false,
      disablePadding: false,
      label: `${t("Home_Dashboard.claims.notification")} (${sellerUnreadCount})`,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.date"),
    },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.product_name"),
    },
    {
      id: "itemPrice",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.price"),
    },
    {
      id: "claims_status",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.claims.status"),
    },
    
  ];

  return (
    <>
      <div className="claims-page directionrtl">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder={t("Home_Dashboard.claims.search_by")}
            name="search_pending_orders"
            id="search_product"
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            className="search-text-field"
          />
          <Button className="search-button">
            <Typography className="btn-text">
              {t("Home_Dashboard.claims.search")}
            </Typography>
          </Button>
        </div>
        <div className="main mobile__view__none">
          {claimsError ? (
            <p className="error-text">{claimsError?.message}</p>
          ) : loadingClaims ? (
            <CircularProgress />
          ) : paginatedData && paginatedData?.length > 0 ? (
            <DashBoardTable
              headCells={headCellsForClaims}
              rows={paginatedData}
              ButtonActions={additionalActionsForEditClaims}
              pageType="claims"
            />
          ) : (
            <NoProductFound />
          )}

          {claimsData && claimsData?.length > 10 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pt: 5,
              }}
              className="deskPagination"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body2">
                  {t("Home_Dashboard.claims.item_per_page")} :{" "}
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outlined"
                  size="small"
                >
                  {array.map((size: any) => (
                    <MenuItem key={size.value} value={size.value}>
                      {size.label}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Pagination
                count={totalPages}
                shape="rounded"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </div>

        <div className="mobile-wrapper mobile__view__block">
          {claimsData && claimsData?.length > 0 ? (
            <MobileTable
              headCells={headCellsForClaims}
              rows={paginatedData}
              onChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
              pageSize={pageSize}
              pageIs={currentPage}
              ButtonActions={additionalActionsForEditClaims}
              count={totalPages}
              pageType="claims"
            />
          ) : (
            <NoProductFound />
          )}
        </div>

        {modalOpen && (
          <ClaimsActionModal
            isOpen={modalOpen}
            onClose={handleModalClose}
            onConfirm={handleConfirm}
            claimData={selectedClaim}
            refundType={refundType}
          />
        )}
      </div>
    </>
  );
};

export default ClaimsPage;
