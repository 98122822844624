import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './NewSidebar.scss';
import { List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SpeedIcon from '@mui/icons-material/Speed';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ReportIcon from '@mui/icons-material/Report';
import { settingsPageQuery } from '../../Operations/Queries';
import { useMutation, useQuery } from '@apollo/client';
import * as mutations from "../../Operations/Mutations";
import { CountryContext } from '../..';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import sellerhublogoEnglish from "../../assets/images/jebelz-seller-logo.jpg";
import sellerhublogoArabic from "../../assets/images/arabicLogo.png";
import i18n from "../../i18n";
// import ClaimsIcon from "../../assets/images/cliamsIcon.png"

const Sidebar = () => {
   const { t }: { t: (key: string) => string } = useTranslation();
   const {store} = useContext(CountryContext);
   const title = window.location.pathname.split("/");
   let topbarHeaderName;
   if (title.length === 4 || title.length === 5) {
      topbarHeaderName = title[2].charAt(0).toUpperCase() + title[2].slice(1)
   } else {
      topbarHeaderName = title[title.length - 1].charAt(0).toUpperCase() + title[title.length - 1].slice(1)
   }
   const selectedItem = topbarHeaderName;
  
   const navigate = useNavigate()
   const loggedInToken = localStorage.getItem("loggedInCust");

   const [revokeCustomertoken] = useMutation(mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      });
      const [logoutTriggered, setLogoutTriggered] = useState(false);
   const { data: SettingsMainData , loading : mainDataLoading } = useQuery(settingsPageQuery.SETTINGS_MAINPAGE, {
      context: {
         headers: {
            Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
            Connection: "keep-alive",
            store: store,
         },
         onError(error: any) {
            if (!logoutTriggered) {
               if (error.message === "Seller is not loggedin") {
                  toast.info(t("Home_Dashboard.sidebar.session_expire_err"), {
                     position: "top-right",
                     autoClose: 3000,
                  });
                  handleLogOut();
               }
            }
         }
      },
   });
   
   const settingsData = SettingsMainData?.sellerData;

   const handleLogOut = async () => {
      if (logoutTriggered) return; 

      setLogoutTriggered(true); 
      try {
         await revokeCustomertoken();
         localStorage.removeItem('selectedMenuItem');
         localStorage.removeItem("preferredLanguage");
         navigate("/")
      } catch (error) {
         console.error('Error revoking token:', error);
         localStorage.removeItem('selectedMenuItem');
         localStorage.removeItem("preferredLanguage");
         navigate("/")
      }
   }

   if(!mainDataLoading && SettingsMainData?.sellerData === undefined ){
      // toast.info(t("Home_Dashboard.sidebar.session_expire_err"), {
      //    position: "top-right",
      //    autoClose: 3000, 
      //  });
      handleLogOut();
   }
 
   return (
      <>
         <div className='sidebar_main directionrtl'>
            <div className='offcanvas offcanvas-start' tab-index="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
               <div className='offcanvas_header'>
                  <div className='header_logo'>
                     <img src={ i18n.language === "ar" ? sellerhublogoArabic : sellerhublogoEnglish} alt="" />
                     <span className='logo_txt text-center'>{`${settingsData?.companyInformation[0]?.firstname} ${settingsData?.companyInformation[0]?.lastname}`}</span>
                  </div>
               </div>

               <div className='offcanvas-body'>
                  <div className='inner_sidebar h-100'>

                     <div className='menu_list h-100' id="sidebar">
                        <nav className='h-100' aria-label="main mailbox folders">
                           <List className='top-menu h-100'>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Dashboard' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Dashboard')}
                                 component={Link}
                                 to="/dashboard"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <SpeedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.dashboard")}className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Product' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Product')}
                                 component={Link}
                                 to="/dashboard/product"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <ProductionQuantityLimitsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.products")}className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Orders' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Orders')}
                                 component={Link}
                                 to="/dashboard/orders"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <EventNoteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.orders")}className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Returns' ? 'active' : ''}
                                 component={Link}
                                 to="/dashboard/returns"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <AssignmentReturnIcon sx={{transform: i18n.language === "ar" ? "rotate(180deg)" : "none" }}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.returns")}className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Payment' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Payment')}
                                 component={Link}
                                 to="/dashboard/payment"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.payment")} className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem?.includes('Claims') ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Settings')}
                                 component={Link}
                                 to="/dashboard/claims"
                              >
                                 <ListItemButton >
                                    {/* <ListItemIcon className='menu-icon'>
                                       <img src={ClaimsIcon} alt = 'claimsIcon'  />
                                    </ListItemIcon> */}
                                    <ListItemIcon className='menu-icon'>
                                       <ReportIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.claims")} className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Settings' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Settings')}
                                 component={Link}
                                 to="/dashboard/settings"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Home_Dashboard.sidebar.setting")}className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                             
                           </List>
                           <List className='bottom-menu'>
                                 <ListItem disablePadding >
                                    <ListItemButton onClick={handleLogOut}>
                                       <ListItemIcon className='menu-icon'>
                                       <LogoutIcon sx={{ transform: i18n.language === "ar" ? "rotate(180deg)" : "none" }} />
                                       </ListItemIcon>
                                       <ListItemText primary={t("Home_Dashboard.sidebar.log_out")} className='menu-name' />
                                    </ListItemButton>
                                 </ListItem>
                              </List>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Sidebar
