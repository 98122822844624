import { gql } from "@apollo/client";

export const UPDATE_SELLER_CLAIM = gql`
  mutation UpdateSellerClaim($entity_id: Int!, $seller_refund_type: String!) {
    updateSellerClaim(
      entity_id: $entity_id
      seller_refund_type: $seller_refund_type
    ) {
      entity_id
      description
      seller_refund_type
      claim_status
      updated_at
    }
  }
`;

export const ADD_CHAT_COMMUNICATION = gql`
mutation addChatCommunication(
  $claimid: String!
  $communication: String!
  $timestamp: String!
  $sender: String!
  $is_read_by_seller: String!
  $is_read_by_customer: String!
) {
  addChatCommunication(
    claimid: $claimid
    communication: $communication
    timestamp: $timestamp
    sender: $sender
    is_read_by_seller: $is_read_by_seller
    is_read_by_customer: $is_read_by_customer
  ) {
    success
    message
  }
}
`;

export const MARK_MESSAGES_READ = gql`
  mutation MarkMessagesRead($claimid: String!, $sender: String!) {
    markMessagesRead(claimid: $claimid, sender: $sender) {
      success
      message
      claimid
    }
  }
`;
