import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import './ClaimsActionModal.scss'; // Import SCSS
import Toaster from '../../../../Components/ToastMessage';
import { claimsMutation } from '../../../../Operations/Mutations';
import { useTranslation } from 'react-i18next';

interface ClaimsActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  claimData: any;
  refundType: string;
  onConfirm : any;
}

const ClaimsActionModal: React.FC<ClaimsActionModalProps> = ({ isOpen, onClose, claimData, refundType ,onConfirm }) => {
  const loggedInToken = localStorage.getItem('loggedInCust');
  const claimId = claimData?.entity_id;
  const { t }: { t: (key: string) => string } = useTranslation();

  const {
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { description: '' },
  });

  const [updateSellerClaim, { loading: updateClaimLoading }] = useMutation(claimsMutation.UPDATE_SELLER_CLAIM, {
    context: {
      headers: {
        Authorization: loggedInToken ? `Bearer ${JSON.parse(loggedInToken)}` : '',
        Connection: 'keep-alive',
      },
    },
  });

  const onSubmit = async (data: { description: string }) => {
    toast.error(t("Home_Dashboard.claims.process_toast"));
    try {
      const { data: response } = await updateSellerClaim({
        variables: {
          entity_id: claimId.toString(),
          // description: data.description,
          seller_refund_type: refundType,
        },
      });

      if (response?.updateSellerClaim) {
        toast.success(t("Home_Dashboard.claims.success_toast"));
        reset();
        onClose();
        onConfirm();
      }
    } catch (error) {
      console.error('Error updating claim:', error);
      toast.error(t("Home_Dashboard.claims.error_toast"));
    }
  };

  return (
    <>
      <Toaster />
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" className="claims-action-modal directionrtl">
        <Box className="modal-container">
          <Box className="modal-header">
            <DialogTitle className="modal-title">{t("Home_Dashboard.claims.claim_action")}</DialogTitle>
            <IconButton onClick={onClose} className="close-button">
              <Close />
            </IconButton>
          </Box>
          <hr className="modal-divider" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{padding:"0px 24px"}}>
              <Box className="refund-type">
                <Typography className="lable">{t("Home_Dashboard.claims.refund_type")} :&nbsp;</Typography>
                <Typography className="value">{refundType === 'full_refund' ? t("Home_Dashboard.claims.full_refund") : refundType === 'partial_refund' ? t("Home_Dashboard.claims.partial_refund") : t("Home_Dashboard.claims.rejected")}</Typography> 
              </Box>

              {/* <Box className="comment-box">
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: 'Comment is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      rows={4}
                      placeholder={t("Home_Dashboard.claims.comment_placeholder")}
                      variant="outlined"
                      className="comment-input"
                      error={!!errors.description}
                      helperText={errors.description ? errors.description.message : ''}
                    />
                  )}
                />
              </Box> */}
            </DialogContent>

            <DialogActions className="modal-actions">
              <Button className="cancel-button" onClick={onClose}>
              {t("Home_Dashboard.claims.cancle_btn")}
              </Button>

              <Button type="submit" className={`submit-button ${refundType === 'rejected' ? 'reject-button' : ''}`} disabled={updateClaimLoading}>
                {updateClaimLoading ? t("Home_Dashboard.claims.processing") : refundType === 'full_refund' || refundType === 'partial_refund' ? t("Home_Dashboard.claims.submit_btn") : t("Home_Dashboard.claims.reject")}
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default ClaimsActionModal;
