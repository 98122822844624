import React, { useEffect, useRef, useState } from "react";
import "./ClaimsProductDetails.scss";
import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Send } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { claimsPageQuery } from "../../../../Operations/Queries";
import CloseIcon from "@mui/icons-material/Close";
import { claimsMutation } from "../../../../Operations/Mutations";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ClaimsProductDetails = () => {
  const [base64Files, setBase64Files] = React.useState<
    { base64: string; name: string }[]
  >([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t }: { t: (key: string) => string } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const claimId = searchParams?.get("claims_id")
    ? parseInt(searchParams.get("claims_id") as string, 10) || 0
    : 0;

  const { data: claimsDatails, loading: claisDatLoading } = useQuery(
    claimsPageQuery.VIEW_CLAIM,
    {
      variables: { entity_id: claimId },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          // store: store,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const claimsData = claimsDatails?.viewSellerSideClaim;

  const {
    data: chatList,
    refetch: refetchChatList,
    loading: chatDataLoading,
    startPolling,
    stopPolling,
    // refetch: refetchClaimDetails,
  } = useQuery(claimsPageQuery.GET_CHAT_COMMUNICATION, {
    variables: { claimid: claimId },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        // store: store,
      },
    },
    fetchPolicy: "network-only",
  });

  const POLL_INTERVAL = 15000;
  const CHECK_INTERVAL = 120000;
  const previousMessageCount = useRef(null);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    startPolling(POLL_INTERVAL);
    const getMessageCount = () =>
      chatList?.getChatCommunication?.communication?.length || 0;

    const checkIntervalId = setInterval(() => {
      const currentCount = getMessageCount();
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
        previousMessageCount.current = currentCount;
        return;
      }

      if (currentCount === previousMessageCount.current) {
        stopPolling();
      } else {
        previousMessageCount.current = currentCount;
        startPolling(POLL_INTERVAL);
      }
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(checkIntervalId);
      stopPolling();
    };
    //eslint-disable-next-line
  }, [chatList]);

  useEffect(() => {
    if (chatList && previousMessageCount.current === null) {
      previousMessageCount.current =
        chatList?.getChatCommunication?.communication?.length || 0;
    }
  }, [chatList]);

  const chatMessages = chatList?.getChatCommunication?.communication;

  useEffect(() => {
    if (chatMessages) {
      const msgCount = chatMessages?.length;
      localStorage.setItem("msgCount", msgCount);
    }
  }, [chatList, chatMessages]);

  const [addChatCommunication, { loading: chatAddLoading }] = useMutation(
    claimsMutation.ADD_CHAT_COMMUNICATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const [markMessagesRead] = useMutation(claimsMutation.MARK_MESSAGES_READ, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  useEffect(() => {
    if (claimId) {
      markMessagesRead({
        variables: {
          claimid: claimId,
          sender: "seller",
        },
      })
        .then()
        .catch((err) => {
          console.error("Failed to mark messages as read:", err);
        });
    }
    //eslint-disable-next-line
  }, [claimId, refetchChatList,messages]);

  useEffect(() => {
    if (claimsData) {
      if (claimsData.image && Array.isArray(claimsData.image)) {
        const imageUrls = claimsData.image;

        const filePromises = imageUrls.map((imageUrl: any) => {
          const imageName =
            imageUrl?.toString().split("/").pop() ?? "default.jpg";

          return fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], imageName, { type: blob.type });

              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  resolve({
                    file: file,
                    base64: reader.result as string,
                    name: file.name,
                  });
                };
              });
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
              return null;
            });
        });

        Promise.all(filePromises)
          .then((results) => {
            const validFiles = results?.filter((item) => item !== null);
            const base64Files = validFiles.map((item) => ({
              base64: item?.base64,
              name: item?.name,
            }));

            setBase64Files(base64Files);
          })
          .catch((error) => console.error("Error processing images:", error));
      }
    }
    //eslint-disable-next-line
  }, [claimsData]);

  const handlePrint = () => {
    const printContent = document.querySelector(".claims-details-main-box");
    const originalContent = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = printContent.outerHTML;
      window.print();
      document.body.innerHTML = originalContent;
      window.location.reload();
    }
  };

  useEffect(() => {
    if (chatMessages) {
      const cleanedMessages = chatMessages?.map((msg: any) => {
        try {
          const cleanedText = msg.text
            .replace(/\\+"/g, "")
            .replace(/^"|"$/g, "");

          return {
            sender: msg?.sender,
            text: cleanedText || "(No message)",
            timestamp: msg?.timestamp,
            is_read_by_seller: msg.is_read_by_seller,
            is_read_by_customer: msg.is_read_by_customer,
          };
        } catch (error) {
          console.error("Failed to parse message:", error);
          return {
            sender: msg?.sender,
            text: "(Invalid message)",
            timestamp: msg?.timestamp,
          };
        }
      });

      setMessages(cleanedMessages);
    }
  }, [chatMessages]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    try {
      const { data } = await addChatCommunication({
        variables: {
          claimid: claimId.toString(),
          communication: message,
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          sender: "seller",
          is_read_by_seller: 1,
          is_read_by_customer: 0,
        },
      });

      if (data?.addChatCommunication?.success) {
        const newMsg = {
          id: messages.length + 1,
          sender: "seller",
          text: message,
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          is_read_by_seller: 1,
          is_read_by_customer: 0,
        };

        setMessages((prev) => [...prev, newMsg]);
        setMessage("");
        refetchChatList();
      } else {
        console.error(
          "Failed to send message:",
          data?.addChatCommunication?.message
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/viewclaim")[0];
    navigate(basePath);
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const FieldSkeleton = ({ width = "80%" }) => (
    <Skeleton variant="text" width={width} height={24} />
  );

  const ChatMessageSkeleton = ({ isSent = false }) => (
    <Box
      className={`chat-message ${isSent ? "sent" : "received"}`}
      sx={{ mb: 2 }}
    >
      <Box className="message-bubble">
        <Skeleton variant="rounded" width={200} height={60} />
        <Skeleton variant="text" width={50} height={16} />
      </Box>
    </Box>
  );

  const FileSkeleton = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 1,
        mt: 1,
        padding: 1,
        border: "1px solid #ccc",
        borderRadius: 1,
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <Box>
        <Skeleton variant="text" width={120} height={24} />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <Skeleton variant="rounded" width={70} height={30} />
        <Skeleton variant="rounded" width={90} height={30} />
      </Box>
    </Box>
  );

  return (
    <>
      <Box className="claim-details-page directionrtl">
        <Box className="heading-sec">
          <Box>
            <h4>{t("Home_Dashboard.claims.title")}</h4>

            <p>{t("Home_Dashboard.claims.desc")}</p>
          </Box>
          <div className="close-btn">
            <button onClick={() => closePage()}>
              <CloseIcon />
            </button>
          </div>
        </Box>

        <Box className="content-sec">
          <Box className="claim-form-heading">
            <h3>{t("Home_Dashboard.claims.small_desc")}</h3>

            <Button onClick={handlePrint} color="primary">
              <SaveAltIcon />
              {t("Home_Dashboard.claims.download_pdf")}
            </Button>
          </Box>

          <Box className="claims-details-main-box">
            <Box className="claims-detl-box">
              <Box className="claims-detl">
                <p className="title">{t("Home_Dashboard.claims.claim_id")}</p>

                {claisDatLoading ? (
                  <FieldSkeleton />
                ) : (
                  <h4>{claimsData?.claimid}</h4>
                )}
              </Box>

              <Box className="claims-detl">
                <p className="title">
                  {t("Home_Dashboard.claims.order_number")}
                </p>

                {claisDatLoading ? (
                  <FieldSkeleton />
                ) : (
                  <h4>{claimsData?.order_id}</h4>
                )}
              </Box>

              <Box className="claims-detl">
                <p className="title">
                  {t("Home_Dashboard.claims.claim_status")}
                </p>

                {claisDatLoading ? (
                  <FieldSkeleton />
                ) : (
                  <h4>
                    {claimsData?.claim_status === "pending"
                      ? t("Home_Dashboard.claims.pending")
                      : claimsData?.claim_status === "in_review"
                      ? t("Home_Dashboard.claims.in_review")
                      : t("Home_Dashboard.claims.close")}
                  </h4>
                )}
              </Box>

              <Box className="claims-detl">
                <p className="title">
                  {t("Home_Dashboard.claims.price_lable")}
                </p>

                {claisDatLoading ? (
                  <FieldSkeleton />
                ) : (
                  <h4>{claimsData?.price}</h4>
                )}
              </Box>

              <Box className="claims-detl">
                <p className="title">{t("Home_Dashboard.claims.date")}</p>

                {claisDatLoading ? (
                  <FieldSkeleton />
                ) : (
                  <h4>
                    {new Date(claimsData?.date).toLocaleDateString("en-GB")}
                  </h4>
                )}
              </Box>
            </Box>

            <Box className="claims-detl-box">
              <Box className="claims-detl">
                <p className="title">{t("Home_Dashboard.claims.issue_type")}</p>
                {claisDatLoading ? (
                  <FieldSkeleton width="100%" />
                ) : (
                  <h4>{claimsData?.issue_type}</h4>
                )}
              </Box>
            </Box>

            <Box className="claims-detl-box">
              <Box className="claims-detl">
                <p className="title">
                  {t("Home_Dashboard.claims.description")}
                </p>

                {claisDatLoading ? (
                  <FieldSkeleton width="100%" />
                ) : (
                  <h4>{claimsData?.description}</h4>
                )}
              </Box>
            </Box>

            <Box className="claims-detl-box">
              <Box className="claims-detl">
                <p className="title">{t("Home_Dashboard.claims.file")}</p>

                <Box>
                  {claisDatLoading ? (
                    <>
                      <FileSkeleton />
                      <FileSkeleton />
                    </>
                  ) : (
                    base64Files?.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 1,
                          mt: 1,
                          padding: 1,
                          border: "1px solid #ccc",
                          borderRadius: 1,
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        <Box>
                          <Typography>{file.name}</Typography>
                        </Box>

                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ height: "30px", textTransform: "none" }}
                            onClick={() => {
                              const fileUrl = file.base64;
                              const newWindow = window.open();
                              if (newWindow) {
                                newWindow.document.write(`
                                            <html>
                                              <head>
                                                <title>Uploaded File</title>
                                                <style>
                                                  body, html {
                                                    margin: 0;
                                                    padding: 0;
                                                    height: 100%;
                                                    display: flex;
                                                    flex-direction: column;
                                                    justify-content: center;
                                                    align-items: center;
                                                    background-color: #f0f0f0;
                                                  }
                                                  img {
                                                    max-width: 100%;
                                                    max-height: 90%;
                                                    object-fit: contain;
                                                  }
                                                  button {
                                                    margin-top: 10px;
                                                    padding: 5px 10px;
                                                    font-size: 16px;
                                                    cursor: pointer;
                                                    margin-top:20px;
                                                  }
                                                </style>
                                              </head>
                                              <body>
                                                <img src="${fileUrl}" alt="Uploaded File" />
                                                <button onclick="window.close()">Close</button>
                                              </body>
                                            </html>
                              `);
                                newWindow.document.close();
                              } else {
                                console.error(
                                  "Popup blocked or new window could not be opened."
                                );
                              }
                            }}
                          >
                            {t("Home_Dashboard.claims.view_file_btn")}
                          </Button>

                          {/* Download File Button */}
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ height: "30px", textTransform: "none" }}
                            onClick={() => {
                              const link = document.createElement("a");
                              link.href = file.base64;
                              link.download = file.name; // Use the file name for download
                              link.click();
                            }}
                          >
                            {t("Home_Dashboard.claims.download_file_btn")}
                          </Button>
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="chat-box">
          <Box sx={{ borderRadius: "2px", p: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={2}
              pt={1}
            >
              <Box sx={{ p: 0, fontWeight: "bold", fontSize: "1.25rem" }}>
                {t("Home_Dashboard.claims.chat_title")}
              </Box>
            </Box>
            <hr style={{ margin: "10px -15px", borderColor: "black" }} />

            <Box>
              <Box
                className="chat-body"
                sx={{ maxHeight: "400px", overflowY: "auto" }}
                ref={chatBoxRef}
              >
                {chatDataLoading ? (
                  <>
                    <ChatMessageSkeleton isSent={false} />
                    <ChatMessageSkeleton isSent={true} />
                    <ChatMessageSkeleton isSent={false} />
                  </>
                ) : messages?.length === 0 ? (
                  <div className="empty-chat">
                    <p>{t("Home_Dashboard.claims.empty_chat_msg")}</p>
                  </div>
                ) : (
                  messages &&
                  messages?.length > 0 &&
                  messages.map((msg) => (
                    <Box
                      key={msg.id}
                      className={`chat-message ${
                        msg.sender === "customer" ? "received" : "sent"
                      }`}
                    >
                      <div className="message-bubble">
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: "pre-line" }}
                        >
                          {msg.text}
                        </Typography>
                        <Typography
                          variant="caption"
                          className={`timestamp ${
                            msg.sender === "customer" ? "received" : "sent"
                          }`}
                        >
                          {msg.timestamp}
                          {msg.sender === "seller" && (
                            <Box
                              component="span"
                              className="message-status"
                              sx={{
                                ml: 0.5,
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              {msg.is_read_by_customer === 1 ? (
                                <span className="read-icon" title="Read">
                                  <DoneAllIcon
                                    sx={{
                                      color: "#2c5ef1",
                                      fontSize: 14,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span className="unread-icon" title="Sent">
                                  <DoneAllIcon
                                    sx={{
                                      color: "rgba(77, 70, 70, 0.45)",
                                      fontSize: 14,
                                    }}
                                  />
                                </span>
                              )}
                            </Box>
                          )}
                        </Typography>
                      </div>
                    </Box>
                  ))
                )}
              </Box>
            </Box>

            <Box className="chat-footer">
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("Home_Dashboard.claims.chat_input_placeholder")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                className="chat-input"
                autoComplete="off"
                inputProps={{
                  autocomplete: "off",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                disabled={!message.trim() || chatAddLoading}
                sx={{ ml: 1 }}
                className="chat-button"
              >
                <Send />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ClaimsProductDetails;
